<template>
  <div v-if="!isSingle" class="list-classic">
    <div class="taxonomy">

      <div class="listing__generic--top no-filter"
           :style="{'background-image': 'url(' + require('@/assets/images/categories/banner-actus.jpeg') + ')'}">
        <h1 :class="{large: this.post.category.length > 16}">
          {{ this.post.category }}
        </h1>
      </div>

      <span v-if="!viewFromHome" @click="returnList" class="return-top">
                    <svg width="89px" height="39px" viewBox="0 0 89 39" version="1.1" xmlns="http://www.w3.org/2000/svg"
                         xmlns:xlink="http://www.w3.org/1999/xlink">
              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Group-5"
                   transform="translate(46.000000, 19.000000) rotate(-180.000000) translate(-46.000000, -19.000000) translate(3.000000, 1.000000)"
                   stroke="#DDDDDD" stroke-width="4">
                  <path d="M0,17.5077573 L85.92,17.5077573" id="Stroke-1"></path>
                  <polyline id="Stroke-3"
                            points="68.4141759 0.0018616 85.9200179 17.5077036 68.4141759 35.0137246"></polyline>
                </g>
              </g>
            </svg>
        {{ $t("main.retour") }}
      </span>
      <div class="list__generic">
        <ul class="row">
          <li v-for="(shop, index) in this.paginate(this.post.shops)" :key="index">
            <ItemGeneric @click="loadSingle(shop.ID)" :excerpt="shop.excerpt" :validity="shop.validity"
                         :title="shop.title" :id="(post.ID)" :logo="shop.logo"/>
          </li>
        </ul>
      </div>
    </div>

    <nav class="pagination-container" aria-label="Page navigation example" v-if="!isSingle && pages.length > 1">
      <ul class="pagination">
        <li class="page-item">
          <div type="button" class="page-prev" :class="{active: page > 1}" @click="page > 1 ? page-- : '' ">
            <svg width="89px" height="39px" viewBox="0 0 89 39" version="1.1" xmlns="http://www.w3.org/2000/svg"
                 xmlns:xlink="http://www.w3.org/1999/xlink">
              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Group-5"
                   transform="translate(46.000000, 19.000000) rotate(-180.000000) translate(-46.000000, -19.000000) translate(3.000000, 1.000000)"
                   stroke="#DDDDDD" stroke-width="4">
                  <path d="M0,17.5077573 L85.92,17.5077573" id="Stroke-1"></path>
                  <polyline id="Stroke-3"
                            points="68.4141759 0.0018616 85.9200179 17.5077036 68.4141759 35.0137246"></polyline>
                </g>
              </g>
            </svg>
          </div>
        </li>
        <li class="page-item page-numbers">

          <button v-if="page > pages.length - 2 && page !== 1 && pages.length > 3" class="page-link no-border">...
          </button>

          <button type="button" class="page-link"
                  :class="{active: pageNumber === page, hide: (pageNumber - page) - 2 > 0}"
                  v-for="(pageNumber, index) in pages.slice(page-1, page+5)"
                  @click="page = pageNumber" v-bind:key="index">
            {{ pageNumber }}
          </button>
          <button v-if="page < pages.length - 2" class="page-link no-border">...</button>
        </li>
        <li class="page-item">
          <div @click="page < pages.length ? page++ : ''" :class="{active:page < pages.length}" class="page-next">
            <svg width="89px" height="39px" viewBox="0 0 89 39" version="1.1" xmlns="http://www.w3.org/2000/svg"
                 xmlns:xlink="http://www.w3.org/1999/xlink">
              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Group-5" transform="translate(0.000000, 2.000000)" stroke="#DDDDDD" stroke-width="4">
                  <path d="M0,17.5077573 L85.92,17.5077573" id="Stroke-1"></path>
                  <polyline id="Stroke-3"
                            points="68.4141759 0.0018616 85.9200179 17.5077036 68.4141759 35.0137246"></polyline>
                </g>
              </g>
            </svg>
          </div>
        </li>
      </ul>
    </nav>

  </div>

  <div v-if="isSingle">
    <SingleGeneric :id="(idOfSingle).toString()" :routeSingle='/shop/' v-on:returnList="listenerChild"/>
  </div>


</template>
<script>

import ItemGeneric from "@/components/ItemGeneric";
import SingleGeneric from "@/components/SingleGeneric";

export default {
  name: "Taxonomy",
  emits: ["returnList"],
  props: {
    post: {},
    viewFromHome: Boolean
  },
  data() {
    return {
      isSingle: false,
      idOfSingle: "",
      page: 1,
      perPage: 8,
      pages: [],
      currentLanguage: this.$i18n.locale
    }
  },
  components: {
    SingleGeneric,
    ItemGeneric,
  },
  created() {
    this.setPages();
  },
  mounted() {

    this.displayedPosts()
    if (document.querySelector(".list__categories") !== null) {
      document.querySelector(".list__categories").style.display = "none";
    }

  },
  methods: {
    returnList() {
      if (document.querySelector(".list__categories") !== null) {
        document.querySelector(".list__categories").style.display = "block";
      }
      // this.$router.push({path: '/magasins', query: {category: this.idOfSingle}});

      this.$emit("returnList", true);
    },
    loadSingle(id) {
      this.isSingle = !this.isSingle;
      this.idOfSingle = id;
      this.$router.push({path: '/magasins', query: {id: id}});

    },
    listenerChild() {
      this.isSingle = !this.isSingle;
      this.$router.push({path: '/magasins', query: {category: this.idOfSingle}});

      if (this.currentLanguage !== this.$i18n.locale) {
        this.currentLanguage = this.$i18n.locale;
        this.$emit('clicked', '')
      }
    },
    setPages() {
      let numberOfPages = Math.ceil(this.post.shops.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(posts) {
      let page = this.page;
      let perPage = this.perPage;
      let from = (page * perPage) - perPage;
      let to = (page * perPage);
      return posts.slice(from, to);
    },
    displayedPosts() {
    }
  },
  filters: {
    trimWords(value) {
      return value.split(" ").splice(0, 20).join(" ") + '...';
    }
  },

}

</script>
