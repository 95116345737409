<template>
  <div class="list__generic" v-if="!isSingle">
    <ul class="row">
      <li v-for="(post, index) in this.paginate()" :key="index">
        <ItemGeneric @click="loadSingle(post.ID)" :excerpt="post.excerpt" :validity="post.validity"
                     :categories="post.categories ? post.categories : post.category " :title="post.title"
                     :date="post.date" :id="(post.ID).toString()" :logo="post.image ? post.image : post.logo "/>
      </li>
    </ul>
  </div>

  <nav class="pagination-container" aria-label="Page navigation example" v-if="!isSingle && pages.length > 1">
    <ul class="pagination">
      <li class="page-item">
        <div type="button" class="page-prev" :class="{active: page > 1}" @click="page > 1 ? page-- : '' ">
          <svg width="89px" height="39px" viewBox="0 0 89 39" version="1.1" xmlns="http://www.w3.org/2000/svg"
               xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="Group-5"
                 transform="translate(46.000000, 19.000000) rotate(-180.000000) translate(-46.000000, -19.000000) translate(3.000000, 1.000000)"
                 stroke="#DDDDDD" stroke-width="4">
                <path d="M0,17.5077573 L85.92,17.5077573" id="Stroke-1"></path>
                <polyline id="Stroke-3"
                          points="68.4141759 0.0018616 85.9200179 17.5077036 68.4141759 35.0137246"></polyline>
              </g>
            </g>
          </svg>
        </div>
      </li>
      <li class="page-item page-numbers">
        <button v-if="page > pages.length - 2 && page !== 1 && pages.length > 3" class="page-link no-border">...
        </button>

        <button type="button" class="page-link"
                :class="{active: pageNumber === page, hide: (pageNumber - page) - 2 > 0}"
                v-for="(pageNumber, index) in pages.slice(page-1, page+5)"
                @click="page = pageNumber" v-bind:key="index">
          {{ pageNumber }}
        </button>
        <button v-if="page < pages.length - 2" class="page-link no-border">...</button>
      </li>
      <li class="page-item">
        <div @click="page < pages.length ? page++ : ''" :class="{active:page < pages.length}" class="page-next">
          <svg width="89px" height="39px" viewBox="0 0 89 39" version="1.1" xmlns="http://www.w3.org/2000/svg"
               xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="Group-5" transform="translate(0.000000, 2.000000)" stroke="#DDDDDD" stroke-width="4">
                <path d="M0,17.5077573 L85.92,17.5077573" id="Stroke-1"></path>
                <polyline id="Stroke-3"
                          points="68.4141759 0.0018616 85.9200179 17.5077036 68.4141759 35.0137246"></polyline>
              </g>
            </g>
          </svg>
        </div>
      </li>
    </ul>
  </nav>

  <div v-if="isSingle">
    <SingleGeneric :id="(idOfSingle).toString()" :routeSingle="routeSingle" v-on:returnList="listenerChild"/>
  </div>


</template>
<script>
import ItemGeneric from "@/components/ItemGeneric";
import SingleGeneric from "@/components/SingleGeneric";

export default {
  name: 'ListGeneric',
  props: {
    posts: Array,
    routeSingle: String,
    msg: String,
    filters: String,

  },
  components: {
    SingleGeneric,
    ItemGeneric
  },
  data() {
    return {
      isSingle: false,
      idOfSingle: "",
      page: 1,
      perPage: 8,
      pages: [],
      filteredPosts: [],
      pathFrom: this.$route.path
    }
  },
  methods: {
    loadSingle(id) {
      this.isSingle = !this.isSingle;
      this.idOfSingle = id;
      let path = this.$route.path;
      if (this.$route.name === 'Loisirs' || this.$route.name === 'Restaurants' || this.$route.name === 'Services') {
        path = '/magasins';
      }

      this.$router.push({path: path, query: {id: id}});
    },
    listenerChild() {
      this.isSingle = !this.isSingle;
      const query = Object.assign({}, this.$route.query);
      delete query.id;
      this.$router.replace({query});
    },

    filterItemsByCategory(posts) {
      return posts.filter(post => {
        if (post.category !== undefined) {
          if (Object.values(post.category).indexOf(this.filters) > -1) {
            return post;
          }
        } else {
          if (Object.values(post.categories).indexOf(this.filters) > -1) {
            return post;
          }
        }
      })
      // return posts.filter(product => !product.category.indexOf(this.category))
    },
    setPages(posts) {
      let numberOfPages = Math.ceil(posts.length / this.perPage);
      this.pages = [];
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate() {
      const posts = this.filteredPosts;
      let page = this.page;
      let perPage = this.perPage;
      let from = (page * perPage) - perPage;
      let to = (page * perPage);
      return posts.slice(from, to);
    },
    resetPage() {
      this.page = 1;
    },
    updateFilter() {
      if (this.filters != undefined) {
        if (this.filters !== "tous") {
          this.filteredPosts = this.filterItemsByCategory(this.posts);
        } else {
          this.filteredPosts = this.posts;
        }
      } else {
        this.filteredPosts = this.posts;
      }

      this.setPages(this.filteredPosts);
    }

  },
  watch: {

    filters() {
      this.updateFilter();
    }
  },
  updated() {
  },
  computed: {},


  mounted() {
    if (this.$route.query.id && !this.isSingle) {
      this.isSingle = !this.isSingle;
      this.idOfSingle = this.$route.query.id;
    }
    this.updateFilter();
  },

}
</script>
