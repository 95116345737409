<template>
  <div class="recherche">
    <Loading v-if="loading" />
    <div v-if="!loading">
      <div class="listing__generic--top"
           :style="{'background-image': 'url(' + require('@/assets/images/categories/banner-search.jpeg') + ')'}">
        <h1>
          {{ $t("menu.recherche") }}
        </h1>
      </div>
      <section class="question">
        <ul>
          <li v-for="(question, index) in faq" :key="index">
            <router-link :to="question.route !== null ? question.route : '' ">
              {{question.title}}
            </router-link>
          </li>
        </ul>
      </section>

    </div>

  </div>


</template>
<script>
// @ is an alias to /src

import axios from "axios";
import {API} from "@/utils/variables";
import Loading from "@/components/Layouts/Loading";

export default {
  name: 'Recherche',
  components: {
    Loading
  },
  props: {
    title: String,
  },
  data() {
    return {
      imageRequire: null,
      faq: Array,
      loading: true,
      errors: [],
      scrollTop: false,
      scrollBottom: false,
    }
  },
  mounted() {
    this.emitter.on("switchLangue", () => {
      if (!this.$store.state.onSingle) {
        this.loading = true;
        this.getPosts();
      }
    });
  },
  async created() {
    await this.getPosts();
  },
  methods: {
    async getPosts() {
      try {
        const response = await axios.get(`${API + this.$i18n.locale}/faq`)
        this.faq = response.data;
        this.loading = false;
      } catch (e) {
        this.errors.push(e)
      }
    },
    scrollListing(navigation) {
      let heightParent = document.querySelector(".question > ul").scrollHeight;
      console.log(heightParent);
      if(navigation === "bottom") {
        this.scrollTop = true;
        this.scrollBottom = true;
        // document.querySelector(".question > ul").scrollTo({
        //   top: heightParent,
        //   behavior: 'smooth'
        // });
      } else {
        this.scrollTop = false;
        this.scrollBottom = false;
        // document.querySelector(".question > ul")..scrollTo({
        //   top: 0,
        //   behavior: 'smooth'
        // });
      }
    }
  }
}
</script>
