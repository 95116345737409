<template>
  <article class="item__generic">
    <div class="content">
      <div class="item__generic--visual" :style="{'background-image': 'url(' + checkImage(logo) + ')'}">
      </div>
      <div class="padding-content">
        <div class="item__generic--content">
          <div class="categories">
            <span v-for="(category, index) in categories" :key="index">
              {{category}}
            </span>
          </div>
          <h2>
            {{title.slice(0, 20) + (title.length > 20 ? "..." : "")}}</h2>
          <p class="date" v-if="validity !== undefined">
            {{validity}}
          </p>
          <p v-html="returnExcerpt()" class="description" v-if="excerpt !== undefined">
          </p>
          <span class="see-more" v-if="$t(`main.${this.$router.currentRoute._rawValue.name}`).length > 2" :id="this.$router.currentRoute._rawValue.name">
            {{ $t(`main.${this.$router.currentRoute._rawValue.name}`) }}
          </span>
        </div>
      </div>
    </div>
  </article>
</template>
<script>
export default {
  name: 'ItemGeneric',
  props: {
    title: String,
    id: String,
    logo: Array,
    date: String,
    validity: String,
    excerpt: String,
    categories: Array,
  },
  methods: {
    checkImage() {
      if(this.logo === undefined) {
        return this.image = require("@/assets/images/no-image.png");
      } else if(Array.isArray(this.logo)) {
        return this.image = this.logo[0];
      } else {
        return this.image = this.logo;
      }
    },
    returnExcerpt() {
      if((this.$t(`main.${this.$router.currentRoute._rawValue.name}`)).length > 2) {
        return this.excerpt.slice(0, 74) + (this.excerpt.length > 20 ? "..." : "");
      } else {
        return this.excerpt.slice(0, 220) + (this.excerpt.length > 20 ? "..." : "");
      }
    }
  },
  data() {
    return {
      posts: Array,
      image: null,
      errors: [],
    }
  },
}
</script>
