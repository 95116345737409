<template>
  <div class="restaurant listing__generic">
    <Loading v-if="loading"/>
    <div v-if="!loading">
      <div class="listing__generic--top no-filter"
           :style="{'background-image': 'url(' + require('@/assets/images/categories/banner-boutiques.jpeg') + ')'}">
        <h1>
          {{ $t("main.restaurants") }}
        </h1>
      </div>

      <ListGeneric :posts="posts" :routeSingle="'/shop/'"/>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import axios from 'axios';
import ListGeneric from "@/components/ListGeneric";
import {API} from "@/utils/variables";
import Loading from "@/components/Layouts/Loading";

export default {
  name: 'Restaurants',
  components: {
    Loading,
    ListGeneric,
  },
  data() {
    return {
      posts: Array,
      loading: true,
      errors: [],
    }
  },
  mounted() {
    this.emitter.on("switchLangue", () => {
      this.loadDataPost();
    })
  },
  methods: {
    async loadDataPost() {
      try {
        const response = await axios.get(`${API + this.$i18n.locale}/shop/list/restaurants`)
        this.posts = response.data;
        this.loading = false;
      } catch (e) {
        this.errors.push(e)
      }
    }
  },
  created() {
    this.loadDataPost();
  }
}
</script>
