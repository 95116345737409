<template>
  <div class="horaire listing__generic">
    <Loading v-if="loading" />
    <div v-if="!loading">
      <div class="listing__generic--top no-filter"
           :style="{'background-image': 'url(' + require('@/assets/images/categories/banner-about.jpeg') + ')'}">
        <h1>
          {{ $t("menu.horaire") }}
        </h1>
      </div>
      <section v-html="post.content"></section>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import axios from 'axios';
import {API} from "@/utils/variables";
import Loading from "@/components/Layouts/Loading";

export default {
  name: 'Loisir',
  components: {
    Loading,
  },
  data() {
    return {
      post: Array,
      loading: true,
      errors: [],
    }
  },
  mounted() {
    this.emitter.on("switchLangue", () => {
      if (!this.$store.state.onSingle) {
        this.loading = true;
        this.getPost();
      }
    });
  },

  async created() {
    await this.getPost();
  },
  methods: {
    async getPost() {
      try {
        const response = await axios.get(`${API + this.$i18n.locale}/timetable`)
        this.post = response.data;
        this.loading = false;
        setTimeout(() => {
          let allTabs = document.querySelectorAll(".tab");

          allTabs.forEach((item) => {
            item.addEventListener("click", () => {
              allTabs.forEach((itemAll) => {
                itemAll.classList.remove("current");
              });
              item.classList.add("current");
            })
          });
        })
      } catch (e) {
        this.errors.push(e)
      }
    },
  }
}
</script>
<style scoped>
.block-top {
  display: none;
}
</style>
