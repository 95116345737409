<template>
  <div class="magasins listing__generic">
    <Loading v-if="loading"/>
    <div v-if="!loading">
      <div class="listing__generic--top"
           :style="{'background-image': 'url(' + require('@/assets/images/categories/banner-actus.jpeg') + ')'}">
        <h1>
          {{ $t("main.boutiques") }}
        </h1>
      </div>
      <div class="wrap__categories">
        <ul class="list__categories">
          <li @click="loadMagasinsAlphabetic" :class="{active:selected == 1}">
            {{ $t("main.ordre_alphabetique") }}
          </li>
          <li @click="loadMagasinsCategory" :class="{active:selected == 2}">
            {{ $t("main.ordre_categorie") }}
          </li>
        </ul>
      </div>
      <ListAlphabetic v-if="currentFilter === 'alphabetic'" :posts="posts" @clicked="listenerChild"/>
      <ListCategories class="list-classic" v-if="currentFilter === 'categories'" :posts="posts" :idCategory="idCategory"
                      @clicked="listenerChild"/>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import axios from 'axios';
import ListAlphabetic from "@/components/ListAlphabetic";
import {API} from "@/utils/variables";
import ListCategories from "@/components/ListCategories";
import Loading from "@/components/Layouts/Loading";

export default {
  name: 'Restaurants',
  components: {
    Loading,
    ListCategories,
    ListAlphabetic,
  },
  data() {
    return {
      posts: Array,
      idCategory: Number,
      errors: [],
      loading: true,
      currentLanguage: this.$i18n.locale,
      selected: 0,
      onSingle: false,
      currentFilter: "alphabetic",
    }
  },
  async created() {
    if (this.$route.query.category) {
      // const queryString = window.location.search;
      // const urlParams = new URLSearchParams(queryString);
      this.idCategory = this.$route.query.category;
      await this.loadMagasinsCategory();
    } else {
      await this.loadMagasinsAlphabetic();
    }

  },
  mounted() {
    // Permet d'éviter de recharger la liste quand on est dans une single

    this.emitter.on("switchLangue", () => {
      if (!this.$store.state.onSingle) {
        this.loading = true;
        if (this.selected === 2) {
          this.loadMagasinsCategory();
        } else {
          this.loadMagasinsAlphabetic();
        }
      }
    });
  },
  methods: {
    async loadMagasinsCategory() {
      this.selected = 2;
      this.loading = true;
      try {
        const response = await axios.get(`${API + this.$i18n.locale}/shop/list/category`)
        this.posts = response.data;
        this.loading = false;
        this.currentFilter = "categories";
      } catch (e) {
        this.errors.push(e)
      }
    },
    async loadMagasinsAlphabetic() {
      this.selected = 1;
      this.loading = true;
      try {
        const response = await axios.get(`${API + this.$i18n.locale}/shop/list/alphabetical`)
        this.posts = response.data;
        this.loading = false;
        this.currentFilter = "alphabetic";
      } catch (e) {
        this.errors.push(e)
      }
    },
    listenerChild() {
      if (this.currentLanguage !== this.$i18n.locale) {
        this.currentLanguage = this.$i18n.locale;

        if (this.selected === 2) {
          this.loadMagasinsCategory();
        } else {
          this.loadMagasinsAlphabetic();
        }
      }
    }
  },
}
</script>
