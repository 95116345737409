<template>
  <div class="listing__generic">
    <Loading v-if="loading" />
    <div v-if="!loading">
      <div class="listing__generic--top no-filter"
           :style="{'background-image': 'url(' + require('@/assets/images/categories/banner-actus.jpeg') + ')'}">
        <h1>
          {{ $t(title) }}
        </h1>
      </div>
      <ListGeneric :posts="posts" :routeSingle="routeSingle" :filters="tous" />
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import axios from 'axios';
import ListGeneric from "@/components/ListGeneric";
import {API} from "@/utils/variables";
import Loading from "@/components/Layouts/Loading";

export default {
  name: 'ListingGeneric',
  components: {
    Loading,
    ListGeneric,
  },
  props: {
    someUnrelatedVar: String,
    apiRoute: String,
    routeSingle: String,
    title: String,
    image: String,
  },
  data() {
    return {
      posts: Array,
      loading: true,
      errors: [],

    }
  },
  mounted() {
    this.emitter.on("switchLangue", () => {
      this.getPosts();
      if (!this.$store.state.onSingle) {
        this.loading = true;
      }
    });

  },
  async created() {
    await this.getPosts();
  },
  methods: {
    async getPosts() {
      try {
        // Gérer dans l'index des routes
        const response = await axios.get(`${API + this.$i18n.locale}${this.apiRoute}`)
        this.posts = response.data;
        this.loading = false;
      } catch (e) {
        this.errors.push(e)
      }
    }
  },
}
</script>
