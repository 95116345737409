<template>
  <article :id="classID" class="single" :class="{navigationAdapteBody: navigation}">
    <Loading v-if="loadingSingle"/>
    <div v-if="!loadingSingle">
      <div class="block-single" v-if="post.code === undefined">
        <div class="block-single-top">
          <div v-html="post.content.rendered ? post.content.rendered : post.content">
          </div>
          <div id="visioglobe-wrapper">
            <div v-if="post.mapwize_shop_id" class="block-visioglobe">

              <!-- place bubble -->
              <div v-if="post.mapwize_shop_id" id="bubble"
                   style="display: none;">
                <div v-if="activePlaceTitle" class="inner-bubble" style="background-color: #ffffff;">
                  <div id="placeIcon"
                       class="uk-position-absolute uk-margin-small-top">
                    <h3 class="placeTitle">{{ activePlaceTitle }}</h3>
                  </div>
                </div>
              </div>
              <div v-if="post.mapwize_shop_id" id="visioglobe-floors">
                <span v-on:click="selectFloor" class="floor floor-2" :class="{active: activeFloor === 'B1-UL2'}"
                      id="B1-UL2">2</span>
                <span v-on:click="selectFloor" class="floor floor-1" :class="{active: activeFloor === 'B1-UL1'}"
                      id="B1-UL1">1</span>
                <span v-on:click="selectFloor" class="floor floor-0" :class="{active: activeFloor === 'B1-UL0'}"
                      id="B1-UL0">0</span>
                <span v-on:click="centerOnShop" data-shop="{{post.mapwize_shop_id}}" id="visioglobe-center-shop"
                      :style="{'background-image': 'url(' + require('@/assets/images/position.png') + ')'}"></span>
              </div>

              <div id="visioglobe-zoom">
                <span v-on:click="zoom" class="zoom" id="zoom-in">+</span>
                <span v-on:click="zoom" class="zoom" id="zoom-out">-</span>
              </div>
            </div>
          </div>
        </div>
        <div class="block-single-bottom">
                <span @click="returnList" class="return">
                    <svg width="89px" height="39px" viewBox="0 0 89 39" version="1.1" xmlns="http://www.w3.org/2000/svg"
                         xmlns:xlink="http://www.w3.org/1999/xlink">
              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Group-5"
                   transform="translate(46.000000, 19.000000) rotate(-180.000000) translate(-46.000000, -19.000000) translate(3.000000, 1.000000)"
                   stroke="#DDDDDD" stroke-width="4">
                  <path d="M0,17.5077573 L85.92,17.5077573" id="Stroke-1"></path>
                  <polyline id="Stroke-3"
                            points="68.4141759 0.0018616 85.9200179 17.5077036 68.4141759 35.0137246"></polyline>
                </g>
              </g>
            </svg>
        {{ viewFromFaq ? $t("main.returnFAQ") : $t("main.retour") }}
      </span>
        </div>
      </div>
    </div>
  </article>
  <div v-if="error" @click="$router.push('/')">
    <Popup :clicked="true" v-on:returnList="listenerChild" :title="title" :description="description"/>
  </div>
</template>
<script>
import axios from "axios";
import {API, API_URL} from "@/utils/variables";
import Popup from "@/components/Layouts/Popup";
import Loading from "@/components/Layouts/Loading";
import {VISIO_GLOBE_HASH_KEY} from "../utils/variables";

export default {
  name: 'SingleGeneric',
  components: {Loading, Popup},
  emits: ["returnList"],
  props: {
    id: String,
    routeSingle: String,
  },
  async created() {

    if (this.$route.params.id !== undefined) {
      this.viewFromFaq = true;
    }
    this.$store.state.onSingle = true;
    if (document.querySelector(".list__categories") !== null) {
      document.querySelector(".list__categories").style.display = "none";
    }
    if (document.querySelector(".listing__generic--top") !== null) {
      document.querySelector(".listing__generic--top").style.display = "none";
    }
    await this.loadDataPost();
    this.currentRouteName()
  },
  mounted() {

    this.emitter.on("switchLangue", () => {
      this.loadingSingle = true;
      this.loadDataPost();
    });

    this.emitter.on("navigationAdapte", (e) => {
      if (e) {
        this.navigation = true;
      } else {
        this.navigation = false;
      }
    });

  },

  methods: {
    centerOnShop() {
      const ess = this.essential;

      try {
        ess.venue.goToPlace({id: this.post.mapwize_shop_id}).then(() => {
          this.activeFloor = ess.content.places[this.post.mapwize_shop_id].floor;
          ess.content.setActivePlace({place: this.post.mapwize_shop_id});
          this.activePlaceTitle = ess.content.places[this.post.mapwize_shop_id].name;

        });
      } catch (e) {
        this.errors.push(e)
      }

    },
    async setPlaceNames() {
      const ess = this.essential
      const me = this;
      await this.getPosts();

      let list = this.shopsList;
      list.forEach(function (shop, key) {
        if (shop.mapwize_shop_id) {
          try {
            ess.content.setPlaceName({
              id: shop.mapwize_shop_id,
              name: list[key].title
            });
          } catch (e) {
            me.errors.push(e)
          }
        }
      });
      this.activePlaceTitle = ess.content.places[this.post.mapwize_shop_id].name;

    },
    async getPosts() {
      try {
        // Gérer dans l'index des routes
        const response = await axios.get(`${API + this.$i18n.locale}/shop/list/all`)
        this.shopsList = response.data;
      } catch (e) {
        this.errors.push(e)
      }
    },
    selectFloor(item) {

      let camera = this.essential._mapviewer.camera;
      let selectedItem = item.target
      const startRadius = camera.position.radius;

      this.essential.venue.goToFloor({id: selectedItem.id}).then(() => {
        this.essential.animateValue(startRadius, 120, {
          duration: 200,
          step: function (radius) {
            camera.position = {
              x: camera.position.x,
              y: camera.position.y,
              radius: radius
            };
          }
        });
      });
      this.activeFloor = selectedItem.id;

    },
    zoom(item) {
      let camera = this.essential._mapviewer.camera;
      const startRadius = camera.position.radius;
      let selectedItem = item.target;
      let toValue = startRadius + 30;
      if (selectedItem.getAttribute('id') === 'zoom-in') {
        toValue = startRadius - 30;
      }
      const ess = this.essential;
      const that = this;
      return this.essential.animateValue(startRadius, toValue, {
        duration: 200,
        step: function (radius) {
          camera.position = {
            x: camera.position.x,
            y: camera.position.y,
            radius: radius
          };
          that.activePlaceTitle = radius < 30 || radius >= 75 ? null : ess.content.places[ess.content.activePlaceID].name;

        }
      });

    },
    returnList() {
      if (this.viewFromFaq) {
        this.$router.push('/recherche')
      } else {
        this.$emit("returnList", true);
      }
    },
    listenerChild() {
      this.error = false;
    },
    currentRouteName() {
      this.classID = this.$route.name.toLocaleLowerCase();
    },
    async loadDataPost() {
      try {
        let response;
        if (this.$route.params.id !== undefined) {
          response = await axios.get(`${API + this.$i18n.locale}${this.routeSingle}${this.$route.params.id}`)
        } else {
          response = await axios.get(`${API + this.$i18n.locale}${this.routeSingle}${this.id}`)
        }
        this.post = response.data;
        if (this.post.code !== undefined) {
          if (this.post.code.toString() === "page not found") {
            console.log("ERREUR");
            this.error = true;
          }
        }

        this.loadingSingle = false;

      } catch (e) {
        this.error = true;
        this.errors.push(e)
      }
    }
  },
  data() {
    return {
      shopsList: Array,
      posts: Array,
      categories: [],
      errors: [],
      navigation: this.$store.state.navigationAdapte,
      loadingSingle: true,
      viewFromFaq: false,
      title: 'singlePopup.titre',
      description: 'singlePopup.description',
      error: false,
      post: Object,
      classID: null,
      essential: Object,
      activeFloor: String,
      activePlaceTitle: String,
      placeIcon: `${API_URL}` + '/wp-content/themes/balexert/css/svg/pin-map.svg'
    }
  },
  watch: {
    post: function () {
      setTimeout(() => {

        // On désactive les liens href pour eviter de quitter la borne
        document.querySelectorAll(".single a").forEach(item => {
          item.addEventListener("click", (e) => {
            e.preventDefault();
          })
        })
        // eslint-disable-next-line no-undef
        if (this.post.mapwize_shop_id !== undefined) {

          var map = document.querySelector(".block-visioglobe #visioglobe2");

          if (map) {
            document.querySelector(".block-visioglobe #visioglobe2").remove();
          }

          const div = document.createElement('div');
          div.setAttribute("id", "visioglobe2");
          document.querySelector(".block-visioglobe").appendChild(div);

          // eslint-disable-next-line no-undef
          let essential = new VisioWebEssential({element: document.getElementById('visioglobe2'), imagePath: '/img/map'});
          essential.setParameters({
            parameters: {
              hash: VISIO_GLOBE_HASH_KEY,
              locale: {
                language: navigator.language
              },
              mapview: {
                colors: {
                  active: "#e53329"
                }
              },
              setPlaceNameOptions: {
                style: 'bold',
              }
            }
          });
          this.essential = essential;
          essential.createMapviewer().then(() => {

            this.setPlaceNames();


            try {

              essential.venue.goToPlace({id: this.post.mapwize_shop_id}).then(() => {

                essential.content.setActivePlace({place: this.post.mapwize_shop_id});

                this.activeFloor = essential.content.places[essential.content.activePlaceID].floor;

                essential.content.placeBubbleEnabled = true;

              });

            } catch (e) {
              this.errors.push(e)
            }

            essential._mapviewer.camera.pitchManipulatorEnabled = false;
            essential._mapviewer.camera.zoomManipulatorEnabled = false;

            essential.onObjectMouseUp = ({targetElement}) => {

              essential.content.setActivePlace({place: targetElement})
              this.activePlaceTitle = essential.content.places[essential.content.activePlaceID].name !== essential.content.places[essential.content.activePlaceID].id ? essential.content.places[essential.content.activePlaceID].name : null;

            };
            this.essential = essential;
          });


        }
      }, 50);

    }
  },
  beforeUnmount() {
    this.$store.state.onSingle = false;
    if (document.querySelector(".list__categories") !== null && document.querySelector(".taxonomy") === null) {
      document.querySelector(".list__categories").style.display = "block";
    }
    if (document.querySelector(".listing__generic--top") !== null && document.querySelector(".taxonomy") === null) {
      document.querySelector(".listing__generic--top").style.display = "flex";
    }

    if (this.essential && Object.keys(this.essential).length > 0) {
      this.essential.off('exploreStateWillChange');
      this.essential.off('navigationComputed');
      this.essential.destroyMapviewer();
    }
  }
}
</script>
