<template>
  <div class="baby">
    <Loading v-if="loading" />
    <div v-if="!loading">
      <div class="listing__generic--top"
           :style="{'background-image': 'url(' + require('@/assets/images/categories/banner-bebe.jpeg') + ')'}">
        <h1>
          {{ $t("main.bebe") }}
        </h1>
      </div>
      <section v-html="post.content"></section>
      <div v-if="!loading" id="visioglobe"></div>
    </div>

  </div>


</template>
<script>
// @ is an alias to /src

import axios from "axios";
import {API} from "@/utils/variables";
import Loading from "@/components/Layouts/Loading";

export default {
  name: 'Cadeau',
  components: {
    Loading
  },
  props: {
    title: String,
  },
  data() {
    return {
      imageRequire: null,
      post: Array,
      loading: true,
      errors: [],
    }
  },
  mounted() {
    this.emitter.on("switchLangue", () => {
      if (!this.$store.state.onSingle) {
        this.loading = true;
        this.getPosts();
      }
    });

  },
  async created() {
    await this.getPosts();
  },
  methods: {
    async getPosts() {
      try {
        const response = await axios.get(`${API + this.$i18n.locale}/baby`)
        this.post = response.data;
        this.loading = false;
        setTimeout(() => {
          var options = {
            apiKey: "756e36c087a6160f96f42cfa78fda635",
            centerOnPlaceId: this.$store.state.borne.idOfBorne

          }
          // eslint-disable-next-line no-undef
          MapwizeUI.map(options).then(() => {
            console.log('Mapwize map and ui are ready to be used')
          })
        }, 100);

      } catch (e) {
        this.errors.push(e)
      }
    },
  }
}
</script>
