<template>
  <div v-if="!isSingle">
    <div id="sd-container-generic-top" @click="scrollListing('top')" :class="{active: scrollTop}"
         class="sd-container sd-generic rotate">
      <div class="arrow"></div>
      <div class="arrow"></div>
    </div>
    <div id="sd-container-generic-bottom" @click="scrollListing('bottom')" :class="{active: scrollBottom}"
         class="sd-container sd-generic">
      <div class="arrow"></div>
      <div class="arrow"></div>
    </div>
    <div class="body-scroll">
      <div class="scroll-container" data-scroll-container>
        <div class="list__alphabetic" data-scroll-section>
          <div v-for="post,key in posts" :key="post">
            <span class="key">
              <strong>{{ key }}</strong>
            </span>
            <ul>
              <li v-for="(offre) in post" :key="offre.message" data-id="{{ offre.ID }}" @click="loadSingle(offre.ID)">
                {{ offre.title }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="isSingle">
    <SingleGeneric :id="(idOfSingle).toString()" :routeSingle="'/shop/'" v-on:returnList="listenerChild"/>
  </div>
</template>
<script>
import SingleGeneric from "@/components/SingleGeneric";
import LocomotiveScroll from 'locomotive-scroll';


export default {

  name: 'ListAlphabetic',
  props: {
    posts: {},
    msg: String,
  },
  components: {
    SingleGeneric,
  },
  methods: {
    loadSingle(id) {
      this.isSingle = !this.isSingle;
      this.idOfSingle = id;
      this.$router.push({path: '/magasins', query: {id: id}});
    },

    listenerChild() {

      this.isSingle = !this.isSingle;

      if (this.$router.options.history.state.back) {
        this.$router.push(this.$router.options.history.state.back);
      } else {
        const query = Object.assign({}, this.$route.query);
        delete query.id;
        this.$router.replace({query});
      }
      if (this.currentLanguage !== this.$i18n.locale) {
        this.currentLanguage = this.$i18n.locale;
        this.$emit('clicked', '')
      }
    },
    scrollListing(navigation) {
      let heightParent = document.querySelector(".list__alphabetic").scrollHeight;
      this.locomotive.update();

      if (navigation === "bottom") {
        if (this.progress < 100) {
          let progressBottom = Math.round(this.progress) + 25;
          this.percentageScroll = Math.round(progressBottom);
          this.locomotive.scrollTo((progressBottom * 0.01) * heightParent)

        }
      } else {
        if (this.progress > 0) {
          let progressTop = Math.round(this.progress) - 25;
          this.percentageScroll = Math.round(progressTop);
          this.locomotive.scrollTo((progressTop * 0.01) * heightParent - 918)
        }
      }
      this.checkScrollArrow();
    },
    checkScrollArrow() {
      if (this.percentageScroll >= 1 && this.percentageScroll <= 100) {
        this.scrollTop = true;
      } else {
        this.scrollTop = false;
      }
      if (this.percentageScroll === 100) {
        this.scrollBottom = true;
      } else {
        this.scrollBottom = false;
      }
    },
    checkScrollArrowProgress() {
      if (this.progress >= 1 && this.progress <= 100) {
        this.scrollTop = true;
      } else {
        this.scrollTop = false;
      }
      if (this.progress === 100) {
        this.scrollBottom = true;
      } else {
        this.scrollBottom = false;
      }
    }

  },
  updated() {
  },
  mounted() {
    if (this.$route.query.id && !this.isSingle) {

      this.isSingle = !this.isSingle;
      this.idOfSingle = this.$route.query.id;
    } else {
      document.querySelector("#sd-container-generic-bottom").addEventListener("click", () => {
      })

      this.locomotive = new LocomotiveScroll({
        el: document.querySelector('[data-scroll-container]'),
        smooth: true,
        lerp: 0.1,
        touchMultiplier: 3,
      });
      this.locomotive.on('scroll', ({limit, scroll}) => {
        const progress = scroll.y / limit.y * 100
        this.progress = progress;
        this.checkScrollArrow();
        this.checkScrollArrowProgress();
      });
      this.locomotive.update();
    }


  },
  data() {
    return {
      isSingle: false,
      scrollTop: false,
      locomotive: null,
      currentLanguage: this.$i18n.locale,
      progress: 0,
      percentageScroll: 0,
      scrollBottom: false,
    }

  },
}
</script>
