<template>
  <div class="listsFilterGeneric listing__generic">
    <Loading v-if="loading" />
    <div v-if="!loading">
      <div class="listing__generic--top"
           :style="{'background-image': 'url(' + require('@/assets/images/categories/banner-actus.jpeg') + ')'}">
        <h1>
          {{ $t(title) }}
        </h1>
      </div>
      <div class="wrap__categories">
        <ul class="list__categories">
          <li :class="{active:selected == -1}" @click="clickAll">{{ $t("main.tous") }}</li>
          <li v-for="(filter, index) in filters" :key="index" @click="clickListCategory(index)"
              :class="{active:selected == Object.keys(filters).indexOf(index)}">
            {{ filter }}
          </li>
        </ul>
      </div>
      <ListGeneric :textBtn="textBtn" :posts="posts" :routeSingle="routeSingle" :filters="currentFilter"/>
    </div>
  </div>
</template>
<script>
// @ is an alias to /
import axios from 'axios';
import ListGeneric from "@/components/ListGeneric";
import {API} from "@/utils/variables";
import Loading from "@/components/Layouts/Loading";

export default {
  name: 'ListingGeneric',
  components: {
    Loading,
    ListGeneric,
  },
  props: {
    someUnrelatedVar: String,
    apiRoute: String,
    routeSingle: String,
    title: String,
    image: String,
    textBtn: String,
  },
  data() {
    return {
      posts: Array,
      loading: true,
      errors: [],
      filters: String,
      selected: -1,
      currentFilter: "tous",
    }
  },
  async created() {
    this.getPosts();
  },
  mounted() {
    this.emitter.on("switchLangue", () => {
      this.getPosts();
      if (!this.$store.state.onSingle) {
        this.loading = true;
      }
    });
  },
  updated() {
  },
  methods: {
    clickListCategory(index) {
      this.switchFilter(index);
      this.selectItem(index);
    },
    clickAll() {
      this.currentFilter = 'tous';
      this.selected = -1;
    },
    switchFilter(filter) {
      this.currentFilter = filter;
    },
    selectItem(index) {
      this.selected = Object.keys(this.filters).indexOf(index)
    },
    async getPosts() {
      try {
        // Gérer dans l'index des routes
        const response = await axios.get(`${API + this.$i18n.locale}${this.apiRoute}`)
        this.posts = response.data.result;
        this.filters = response.data.filters;
        this.loading = false;
      } catch (e) {
        this.errors.push(e)
      }
    }
  },
}
</script>
