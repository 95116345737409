<template>
  <div class="cadeau">
    <Loading v-if="loading" />
    <div v-if="!loading">
      <div class="listing__generic--top"
           :style="{'background-image': 'url(' + require('@/assets/images/categories/banner-cadeau.jpg') + ')'}">
        <h1>
          {{ $t("home.cadeau") }}
        </h1>
      </div>
      <section v-html="post.content"></section>

    </div>

  </div>


</template>
<script>
// @ is an alias to /src

import axios from "axios";
import {API} from "@/utils/variables";
import Loading from "@/components/Layouts/Loading";

export default {
  name: 'Cadeau',
  components: {
    Loading
  },
  props: {
    title: String,
  },
  data() {
    return {
      imageRequire: null,
      post: Array,
      loading: true,
      errors: [],
    }
  },
  mounted() {
    this.emitter.on("switchLangue", () => {
      this.getPosts();
      if (!this.$store.state.onSingle) {
        this.loading = true;
      }
    });
  },
  async created() {
    await this.getPosts();
  },
  methods: {
    async getPosts() {
      try {
        const response = await axios.get(`${API + this.$i18n.locale}/gift-cards`)
        this.post = response.data;
        this.loading = false;
      } catch (e) {
        this.errors.push(e)
      }
    },
  }
}
</script>
