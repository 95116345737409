<template>
  <div class="transport">
    <div class="listing__generic--top"
         :style="{'background-image': 'url(' + require('@/assets/images/categories/banner-transport.jpeg') + ')'}">
      <h1>
        {{ $t("home.transports") }}
      </h1>
    </div>
    <iframe src="/transport/tpg_2021.html" sandbox="allow-scripts allow-forms allow-same-origin"></iframe>
  </div>
</template>
<script>
export default {
  name: 'Transport',
  data() {
  },
  created() {

  },
  mounted() {
  }
}
</script>
