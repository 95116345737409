<template>
  <div class="ou">
    <Loading v-if="loading"/>
    <div id="visioglobe-wrapper">
      <div id="visioglobe"></div>

      <!-- place bubble -->
      <div id="bubble" class="uk-card uk-card-default uk-width-large uk-height-max-medium"
           style="display: none;">
        <div v-if="activePlaceTitle" class="inner-bubble" style="background-color: #ffffff;">
          <div id="placeIcon"
               class="uk-position-absolute uk-margin-small-top">
            <h3 class="placeTitle">{{ activePlaceTitle }}</h3>
          </div>
        </div>
      </div>

      <div id="visioglobe-floors">
      <span v-on:click="selectFloor" class="floor floor-2" :class="{active: activeFloor === 'B1-UL2'}"
            id="B1-UL2">2</span>
        <span v-on:click="selectFloor" class="floor floor-1" :class="{active: activeFloor === 'B1-UL1'}"
              id="B1-UL1">1</span>
        <span v-on:click="selectFloor" class="floor floor-0" :class="{active: activeFloor === 'B1-UL0'}"
              id="B1-UL0">0</span>
        <span v-on:click="centerOnBorne" data-shop="{{$store.state.borne.idOfBorne}}" id="visioglobe-center-shop"
              :style="{'background-image': 'url(' + require('@/assets/images/position.png') + ')'}"></span>
        <div id="visioglobe-zoom">
          <span v-on:click="zoom" class="zoom" id="zoom-in">+</span>
          <span v-on:click="zoom" class="zoom" id="zoom-out">-</span>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
// @ is an alias to /src

import Loading from "@/components/Layouts/Loading";
import {VISIO_GLOBE_HASH_KEY} from "../utils/variables";
import axios from "axios";
import {API, API_URL} from "@/utils/variables";

export default {
  name: 'Ou',
  components: {
    Loading
  },
  data() {
    return {
      loading: true,
      map: null,
      shopsList: Array,
      errors: [],
      essential: Object,
      activeFloor: String,
      heading: Number,
      activePlaceTitle: String,
      placeIcon: `${API_URL}` + '/wp-content/themes/balexert/css/svg/pin-map.svg'
    }
  },
  methods: {

    centerOnBorne() {
      const ess = this.essential;

      ess.content.setActivePlace({place: this.$store.state.borne.idOfBorne});
      this.activeFloor = ess.content.places[this.$store.state.borne.idOfBorne].floor;
      ess.venue.goToPlace({
        id: ess.content.activePlaceID,
        heading: this.heading,
        radius: 200
      });
      this.activePlaceTitle = ess.content.places[ess.content.activePlaceID].name !== ess.content.places[ess.content.activePlaceID].id ? ess.content.places[ess.content.activePlaceID].name : null;

    },
    selectFloor(item) {

      let camera = this.essential._mapviewer.camera;
      const startRadius = camera.position.radius;
      let selectedItem = item.target

      this.essential.venue.goToFloor({id: selectedItem.id}).then(() => {
        this.essential.animateValue(startRadius, 220, {
          duration: 200,
          step: function (radius) {
            camera.position = {
              x: camera.position.x,
              y: camera.position.y,
              radius: radius
            };
          }
        });
      });
      this.activeFloor = selectedItem.id;

    },
    zoom(item) {
      let camera = this.essential._mapviewer.camera;
      const startRadius = camera.position.radius;
      let selectedItem = item.target;
      let toValue = startRadius + 60;
      if (selectedItem.getAttribute('id') === 'zoom-in') {
        toValue = startRadius - 60;
      }

      const ess = this.essential;
      const that = this;
      return this.essential.animateValue(startRadius, toValue, {
        duration: 200,
        step: function (radius) {
          camera.position = {
            x: camera.position.x,
            y: camera.position.y,
            radius: radius
          };
          that.activePlaceTitle = radius <= 80 || radius >= 320 ? null : ess.content.places[ess.content.activePlaceID].name;

        }
      });

    },
    async getPosts() {
      try {
        // Gérer dans l'index des routes
        const response = await axios.get(`${API + this.$i18n.locale}/shop/list/all`)
        this.shopsList = response.data;
      } catch (e) {
        this.errors.push(e)
      }
    },
    async setPlaceNames() {
      const ess = this.essential

      await this.getPosts();

      let list = this.shopsList
      list.forEach(function (shop, key) {
        if (shop.mapwize_shop_id) {
          try {
            ess.content.setPlaceName({
              id: shop.mapwize_shop_id,
              name: list[key].title
            });
          } catch (e) {
            console.log(e);
          }
        }
      });
      this.loading = false;
    },

    loadMap() {

      setTimeout(() => {

        if (document.querySelector("#visioglobe") !== null) {

          //this.$store.state.borne.idOfBorne = "60be2629e9fbd7001432dc64";
          switch (this.$store.state.borne.idOfBorne) {
            case "60be23b00355aa001469046d":
              // mapwize_value = -0.8795313136104724;
              this.heading = 231.25;
              break
            case "60be247149055e00239becb7":
              // mapwize_value = 0.69;
              this.heading = 321.25;
              break
            case "60be24a70355aa0014690472":
              // mapwize_value = 2.267777643802875;
              this.heading = 231.25;
              break
            case "60be24deee9e3e0014c01f4b":
              // mapwize_value = 2.267777643802875;
              this.heading = 231.25;
              break
            case "60be2629e9fbd7001432dc64":
              // mapwize_value = -0.859852375770972;
              this.heading = 51.25;
              break
            case "60be2643130c780014e17af6":
              // mapwize_value = -0.873815009786926;
              this.heading = 51.25;
              break
            case "60be2657e9fbd7001432dc66":
              // mapwize_value = -2.4515926535898016;
              this.heading = 141.25;
              break
            case "60be267b130c780014e17af9":
              // mapwize_value = 2.0535817241413192;
              this.heading = 51.25;
              break

          }

          // eslint-disable-next-line no-undef
          const essential = new VisioWebEssential({element: document.getElementById('visioglobe'), imagePath: '/img/map'});
          essential.setParameters({
            parameters: {
              hash: VISIO_GLOBE_HASH_KEY,
              locale: {
                language: navigator.language
              },
              mapview: {
                colors: {
                  active: "#e53329"
                }
              },
              setPlaceNameOptions: {
                style: 'bold',
              }
            }
          });


          essential.createMapviewer().then(() => {

            this.setPlaceNames();


            var resizeEvent = new Event('resize');
            window.dispatchEvent(resizeEvent);

            this.activeFloor = essential.content.places[this.$store.state.borne.idOfBorne].floor

            this.activePlaceTitle = null;

            essential.content.placeBubbleEnabled = true;

            let that = this;
            setTimeout(function () {
              essential.content.setActivePlace({place: that.$store.state.borne.idOfBorne});
              essential.venue.goToPlace({
                id: that.$store.state.borne.idOfBorne,
                heading: that.heading,
                radius: 200
              });


            }, 50);

            essential._mapviewer.camera.pitchManipulatorEnabled = false;
            essential._mapviewer.camera.zoomManipulatorEnabled = false;

            essential.onObjectMouseUp = ({targetElement}) => {
              essential.content.setActivePlace({place: targetElement});
              essential.venue.goToPlace({
                id: essential.content.activePlaceID,
                heading: this.heading,
                radius: 200
              });
              this.activePlaceTitle = essential.content.places[essential.content.activePlaceID].name !== essential.content.places[essential.content.activePlaceID].id ? essential.content.places[essential.content.activePlaceID].name : null;

            };

          });

          this.essential = essential;

          this.emitter.on("navigationAdapte", () => {
            setTimeout(() => {
              var resizeEvent = window.document.createEvent('UIEvents');
              resizeEvent.initUIEvent('resize', true, false, window, 0);
              window.dispatchEvent(resizeEvent);
            }, 50)
          });
        }
      }, 50);

    },
    contextMenuClick(element) {
      var evt = element.ownerDocument.createEvent('MouseEvents');

      var RIGHT_CLICK_BUTTON_CODE = 2; // the same for FF and IE

      evt.initMouseEvent('contextmenu', true, true,
          element.ownerDocument.defaultView, 1, 0, 0, 0, 0, false,
          false, false, false, RIGHT_CLICK_BUTTON_CODE, null);

      if (document.createEventObject) {
        // dispatch for IE
        return element.fireEvent('onclick', evt)
      } else {
        // dispatch for firefox + others
        return !element.dispatchEvent(evt);
      }
    }

  },
  mounted() {
    // Permet de savoir si le script est chargé et qu'on vient de la page ou
    if (this.$store.state.scriptLoaded) {
      this.loadMap();
    } else {
      this.emitter.on("scriptLoaded", () => {
        this.loadMap();
      });
    }
  },
  beforeUnmount() {
    if (this.essential) {
      this.essential.off('exploreStateWillChange');
      this.essential.off('navigationComputed');
      this.essential.destroyMapviewer();
    }
  }
}
</script>
