<template>
  <div class="list__categories--magasin" v-if="!loadTaxonomy">
    <div>
      <ul>
        <li v-for="post in posts" :key="post" @click="loadViewTaxonomy(post);" class="key" :id="post.categoryID">
          {{ post.category }}
          <strong>
            ({{ post.shops.length }})
          </strong>
        </li>
      </ul>
    </div>
  </div>

  <div class="block-taxo" v-if="loadTaxonomy">
    <Taxonomy :viewFromHome="viewFromHome" :post="currentTaxonomy" v-on:returnList="listenerChild"
              @clicked="listenerChild"/>
  </div>
</template>
<script>
import Taxonomy from "@/components/Categories/Taxonomy";

export default {
  name: 'ListCategories',
  components: {Taxonomy},
  props: {
    posts: Array,
    msg: String,
    idCategory: Number,
  },
  methods: {
    loadViewTaxonomy(post) {
      this.viewFromHome = false
      this.loadTaxonomy = true;
      this.currentTaxonomy = post;
      this.$router.push({path: '/magasins', query: {category: post.categoryID}});

      if (document.querySelector(".listing__generic--top") !== null) {
        document.querySelector(".listing__generic--top").style.display = "none";
      }
    },
    listenerChild() {
      this.loadTaxonomy = false;
      const query = Object.assign({}, this.$route.query);
      delete query.category;
      this.$router.replace({query});

      if (document.querySelector(".listing__generic--top") !== null) {
        document.querySelector(".listing__generic--top").style.display = "flex";
      }
      if (this.currentLanguage !== this.$i18n.locale) {
        this.currentLanguage = this.$i18n.locale;

        this.$emit('clicked', '')
      }
    }
  },
  updated() {
  },
  mounted() {

    if (this.idCategory > 1) {
      document.getElementById(`${this.idCategory}`).click();
      this.viewFromHome = true;
      // On nettoye l'url si on vient de la page FAQ qui a dans l'url la catégorie en paramètre
      // window.history.replaceState(null, null, window.location.pathname);
    }
  },
  created() {
  },
  data() {
    return {
      currentTaxonomy: "",
      loadTaxonomy: false,
      currentLanguage: this.$i18n.locale,
      viewFromHome: false

    }
  },
}
</script>
